import { useEffect, useState } from "react";
import { UnsubscribeService } from "services/unsubscribe.service";
import { Button, Stack } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import LoadingProgressLinear from "components/LoadingProgressLinear";
import PlainClientLogoLayout from "layouts/PlainClientLogoLayout";
import { LandingLoadingLayout } from "layouts/LandingLoadingLayout";

export const Unsubscribe = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const clientId = urlParams?.get('ci')?.replace(/\s/g, '+');
    const contactid = urlParams?.get('cti')?.replace(/\s/g, '+');

    const [data, setData] = useState(null)
    const [unsubscribedTags, setUnsubscribedTags] =  useState(null)
    const [subscribedTags, setSubscribedTags] =  useState(null)
    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(null)

    useEffect(() => {
        if (clientId === undefined) {
            setHasError("The link is invalid or expired. Please contact the sender for more information.")
            setLoading(false)
        }
        else {
            UnsubscribeService.data.subscribe((res) => {
                setData(res);
                if(res!=null){
                setSubscribedTags(res.tags.filter(tag => tag.isContactAssociated === true));
                setUnsubscribedTags(res.tags.filter(tag => tag.isContactAssociated === false));
                }
            })

            if (data === null) {
                UnsubscribeService.getContact(clientId, contactid)
                    .catch((err) => {
                        setHasError("The link is invalid or expired. Please contact the sender for more information.")
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }, [])

    const unsubscribeToTag = async (tag) => {
        setLoading(true)
        UnsubscribeService.unsubscribeNewsletterTag(clientId, contactid, tag)
            .catch((err) => {
                console.log(err);
            }
            ).finally(() => {
                setLoading(false)
            });
    }

    const subscribeToTag = async (tag) => {
        setLoading(true)
        UnsubscribeService.subscribeNewsletterTag(clientId, contactid, tag)
            .catch((err) => {
                console.log(err);
            }
            ).finally(() => {
                setLoading(false)
            });
    }


    const unsubscribe = async () => {
        setLoading(true)
        UnsubscribeService.unsubscribe(clientId, contactid)
            .catch((err) => {
                console.log(err);
            }
            ).finally(() => {
                setLoading(false)
            });
    }

    return (

        <>
            {loading &&
                <LandingLoadingLayout loading={loading}></LandingLoadingLayout>
            }

            {
                hasError &&
                <PlainClientLogoLayout clientLogoPath={data?.clientLogo}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                        <div style={{
                            backgroundColor: "#f5f5f5",
                            width: "500px",
                            padding: "20px 50px",
                            borderRadius: "10px",
                            textAlign: "center",
                            margin: "50px auto"
                        }}>
                            {hasError}
                        </div>
                    </div>
                </PlainClientLogoLayout>
            }
            {!hasError && data &&
                <PlainClientLogoLayout clientLogoPath={data?.clientLogo}>
                    <LoadingProgressLinear isLoading={loading} />
                    {data &&
                        <Card style={{ width: '40rem', margin: 'auto', textAlign: "center" }}>
                            <Card.Body>
                                {data.isUnsubscribed &&
                                    <span>
                                        <strong> {data.email} </strong>
                                        is unsubscribed from all communications for this service.
                                    </span>
                                }
                                {!data.isUnsubscribed &&
                                    <Stack gap={3}>
                                        <h3>
                                            <strong> {data.email} </strong>
                                            subscription details
                                        </h3>

                                        {data.tags && data.tags.length > 0 &&
                                            <>
                                                { subscribedTags && subscribedTags.length > 0 && <>

                                                <span>You are currently subscribed to the following services, to change your subscription, please click on the tags below:</span>
                                                <Stack direction="horizontal" gap={1} style={{ justifyContent: "center" }}>
                                                    {
                                                        subscribedTags.map((tag, index) => {
                                                            return <Button key={index} variant="danger" onClick={() => unsubscribeToTag(tag)} disabled={loading}>{tag.tag}</Button>
                                                        })
                                                    }
                                                </Stack>
                                                <hr/>
                                                </>
                                                }

                                                { unsubscribedTags && unsubscribedTags.length > 0 && <>

                                                <span>You are currently unsubscribed to the following services, to change your subscription, please click on the tags below:</span>

                                                <Stack direction="horizontal" gap={1} style={{ justifyContent: "center" }}>
                                                    {
                                                        unsubscribedTags.map((tag, index) => {
                                                            return <Button key={index} variant="success" onClick={() => subscribeToTag(tag)} disabled={loading}>{tag.tag}</Button>
                                                        })
                                                    }
                                                </Stack>
                                                <hr />
                                                </>
                                                }
                                            </>
                                        }

                                        <span>If you want to unsubscribe your email address from all communications for this service, please click below</span>
                                        <div>
                                            <Button variant="danger" onClick={unsubscribe} disabled={loading}>Unsubscribe</Button>
                                        </div>
                                        <hr />
                                        <span>
                                            <div>Please Note:</div>
                                            Upon 'unsubscribing' the address listed above will no longer receive any further emails from the  {data.clientName}  via this service.
                                            Details of this action will also be forwarded to {data.clientName} , however {data.clientName}  cannot guarantee that your address will be removed from other mailing lists operated by {data.clientName}.
                                        </span>
                                    </Stack>
                                }
                            </Card.Body>
                        </Card>
                    }
                </PlainClientLogoLayout>
            }
        </>

    )
}