
import { config } from 'config';
import { fetchWrapperCustomAuth } from 'helpers/fetch-wrapper-custom-auth-header';
import { BehaviorSubject } from 'rxjs';

const baseUrl = `${config.apiUrl}/api/unsubscribe`;

const subject = new BehaviorSubject(null);

export const UnsubscribeService = {
    data: subject.asObservable(),
    getContact: function (clientId, contactid) {
        const data =   {
            ci: clientId,
            cti: contactid
        };

        //convert data to querystring
        var querystring = Object.keys(data).map(key => key + '=' + data[key]).join('&');

        return fetchWrapperCustomAuth.get(`${baseUrl}/getContact?${querystring}`, clientId).then(contact => {
                subject.next(contact);
                return contact;
            });
    },
    unsubscribeNewsletterTag: function (clientId, contactid, tag) {
        return fetchWrapperCustomAuth.post(`${baseUrl}/UnsubscribeNewsletterTag`,
            {
                ci: clientId,
                cti: contactid,
                tags: [tag]
            },
            clientId).then(contact => {
                this.getContact(clientId, contactid);
            });

    },
    subscribeNewsletterTag: function (clientId, contactid, tag) {
        return fetchWrapperCustomAuth.post(`${baseUrl}/SubscribeNewsletterTag`,
            {
                Ci: clientId,
                Cti: contactid,
                Tags: [tag]
            },
            clientId).then(contact => {
                this.getContact(clientId, contactid);
            });

    },
    unsubscribe:  function (clientId, contactid) {
        return fetchWrapperCustomAuth.post(`${baseUrl}`,
            {
                ci: clientId,
                cti: contactid
            },
            clientId).then(contact => {
                this.getContact(clientId, contactid);
            });
    }

};
